import Flickity from 'flickity-fade';
import Plyr from 'plyr';
import gsap from 'gsap';
import axios from 'axios';
import LazyLoad from 'vanilla-lazyload';
import inView from 'in-view'

const HTML = document.querySelector('html');

class App {
  constructor() {
    let currentState = null;

    window.addEventListener('popstate', function (event) {

      if( currentState != event.state ) {
        currentState = event.state

        if( currentState && currentState.day ) {
          const cBox = document.querySelector(`[data-day="${currentState.day}"]`);

          if( cBox ) {
            cBox.dispatchEvent(new Event('click'));
          }
        } 
        // Close day
        else {
          const close = document.querySelector('.btn-back')
          if( close ) {
            close.dispatchEvent(new Event('click'));
          }
        }
      }
    });

    const slider = document.querySelector('.slider');
    if( slider ) {
      new Flickity(slider, {
        lazyLoad : 3,
        prevNextButtons : false,
        pageDots : false,
        fade : true,
        autoPlay: 4000,
        pauseAutoPlayOnHover: false,
        draggable: false,
      })
    }

    // Lazyload
    this.lazyload = new LazyLoad({
      elements_selector: '.js-lazy',
      threshold: 600,
      callback_enter: ($el) => {
        if ($el.classList.contains('js-lazy-error-placeholder')) {
          $el.lazyLoadPlaceholder = $el.src;
        }
      }
    });

    this.popup(HTML);

    // Open box
    const boxes = document.querySelectorAll('.box--trigger');
    if( boxes) {
      // Init animation timeline 
      const tlBoxes = gsap.timeline({ paused : true })
      const elts = [...boxes].sort(function(){return 0.5-Math.random()});
      tlBoxes.fromTo(elts, {
        scale: 0,
        autoAlpha: 1
      }, {
        duration: 0.3,
        ease: 'back.out(1.2)',
        scale: 1,
        stagger: .05
      })

      boxes[0].closest('.js-reveal').addEventListener('is-reveal', () => {
        tlBoxes.play();
      })

      boxes.forEach(box => {
        box.addEventListener('click', (e) => {
          e.preventDefault();

          const inner = box.querySelector('.box--inner');
          const day = box.dataset.day

          if( gtag ) {
            gtag('event', 'page_view', {
              'page_location': window.location.href,
              'page_path': '/jour-' + day,
              'page_title': box.getAttribute('title')
            });
          }

          // Update the url
          if( history ) {
            currentState = {day : day}
            history.pushState(currentState, box.getAttribute('title'), './jour-' + day);
          }

          HTML.classList.add('show-day')

          // Hide case
          gsap.to(inner, { duration : 0.3, autoAlpha : 0, onComplete : () => {

            // Load the template
            axios({
              method: 'get',
              url : './inc/.day.php',
              params : {
                day : day
              }
            })
            .then((response) => {
              const pos = box.getBoundingClientRect();
  
              const output = document.createElement('div');
              output.innerHTML = response.data;
  
              const boxHtml = output.querySelector('.box-day');
              document.body.append(boxHtml);

              // Init close box btn
              const close = boxHtml.querySelector('.btn-back')
              close.addEventListener('click', (e) => {
                e.preventDefault();

                if( history ) {
                  currentState = {}
                  history.pushState(currentState, '', './');
                }

                gsap.to(boxHtml.children, { duration : 0.3, autoAlpha : 0, onComplete: () => {
                  gsap.to(
                    boxHtml, 
                    { 
                      duration: 0.4,
                      x: pos.x, 
                      y: pos.y, 
                      width: pos.width, 
                      height: pos.height,
                      ease: 'expo.inOut',
                      delay: 0.1,
                      onComplete: () => {
                        HTML.classList.remove('show-day')
                        gsap.to(boxHtml, { duration: .3, autoAlpha: 0, onComplete : () => { boxHtml.remove() }})
                      }
                    }
                  )

                }})
              })

              // Init listener on the box
              this.box(boxHtml);
  
              // Move the box
              gsap.fromTo(
                boxHtml, 
                { 
                  x: pos.x, 
                  y: pos.y, 
                  width: pos.width, 
                  height: pos.height 
                },
                {
                  duration: 0.4,
                  x: 0,
                  y: 0,
                  width: '100vw',
                  height: '100vh',
                  ease: 'expo.inOut',
                  onComplete: () => {
                    gsap.set(boxHtml, { clearProps : 'all'})
                    gsap.set(inner, { clearProps: 'all'});

                    setTimeout(() => {
                      boxHtml.classList.add('-show');
                    }, 300)
                  }
                }
              )
  
            })
          
          } });
        })
      })
    }

    // If already day clicked
    if( CURRENT_DAY ) {
      const cBox = document.querySelector(`[data-day="${CURRENT_DAY}"]`);

      if( cBox ) {
        const pos = cBox.getBoundingClientRect();

        gsap.to(
          document.querySelectorAll('html, body'),
          {
            duration : 0.5,
            scrollTop : pos.y * 0.9 + window.scrollY,
            onComplete : () => {
              setTimeout(() => {
                cBox.dispatchEvent(new Event('click'));
              }, 200)
            }
          }
        )
      }
    }
    


		inView.offset(window.innerHeight * .1);
		inView('.js-reveal:not(.is-visible)')
    	.on('enter', function(elt) { 
    		elt.classList.add('is-visible');

    		elt.dispatchEvent(new Event('is-reveal'))
    	})
  }

  box(el) {

    // Video custom player
    const videos = el.querySelectorAll('.video');
    if( videos ) {
      videos.forEach( video => {
        const playerElt = video.querySelector('.video--player');
        const player = new Plyr(playerElt, {
          controls : ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
          poster: video.dataset.poster,
          vimeo : { byline: false, portrait: false, title: false, speed: true, transparent: false, playsinline: false }
          // autoplay: true
        });

        player.on('timeupdate', () => {
          if(player.currentTime >= player.duration - 1) {
            player.stop();
          }
        })
      })
    }

    // Init popup
    const trigger = el.querySelector('.trigger-popup');
    trigger.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      HTML.classList.add('show-popup');
    })
  }

  popup(el) {
    const close = el.querySelector('.trigger-close');
    close.addEventListener('click', e => {
      e.preventDefault();
      HTML.classList.remove('show-popup');
    })
    HTML.addEventListener('click', (e) => {
      if( HTML.classList.contains('show-popup') && 
          !e.target.classList.contains('popup') && 
          !e.target.closest('.popup') ) {
        HTML.classList.remove('show-popup');
      }
    })

    // Init form
    const form = el.querySelector('form');
    const msg = document.querySelector('.msg');
    form.addEventListener('submit', (e) => {
      const btn = form.querySelector('button');
      e.preventDefault();

      gsap.to(btn, { duration : 0.3, opacity: 0.4, pointerEvents : 'none' });
      
      const formD = new FormData(form);
      formD.append('action', 'save-game');

      axios({
        method: 'post',
        url : './inc/.ajax.php',
        data : formD
      })
      .then((response) => {
        const msgError = document.createElement('div');
        msgError.classList.add('bg-blue-500', 'p-5', 'mb-6', 'w-full');
        
        if( response.data ) {

          if( response.data.statut ) {
            msg.innerHTML = "Votre participation a bien été enregistrée."

            setTimeout(() => {
              form.reset();
            }, 400);

            msg.classList.remove('translate-y-full')
            setTimeout(() => {
              msg.classList.add('translate-y-full')
            }, 6000);
  
            HTML.classList.remove('show-popup');
          } else {
            msgError.innerHTML = response.data.msg;
            form.prepend(msgError);
            setTimeout(() => {
              msgError.remove()
            }, 6000);
          }
        } else {
          msgError.innerHTML = 'Une erreur s\'est produite pendant l\'enregistrement de votre participation';
          form.prepend(msgError);
          setTimeout(() => {
            msgError.remove()
          }, 6000);
        }

        gsap.to(btn, { duration : 0.3, opacity: 1, pointerEvents : 'auto' });
      })

      return false;
    })
  }
}

 
window.addEventListener('load', () => {
  new App();
})